import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MediaFile } from '../shared/types/videofile';
import { MediaService } from '../shared/service/media.service';

export interface KeyRequest {
  kids: string[];
  type: string;
}
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  @Input() videoFile!: MediaFile;
  @ViewChild('ftiMegaPlayer') player!: any;

  config = [{
    initDataTypes: ['webm'],
    videoCapabilities: [
      {
        contentType: 'video/webm;codecs=vp8',
      },
      {
        contentType: 'video/webm;codecs=vp9',
      },

    ],
  }];

  isEncrypted = false;

  constructor(private videoService: MediaService) {}

  ngAfterViewInit() {
    const videoElement = this.player.nativeElement as HTMLVideoElement;
    const handler = this.handleEncrypted.bind(this);
    videoElement.addEventListener('encrypted', handler, false);
    navigator.requestMediaKeySystemAccess('org.w3.clearkey', this.config).then(
      (keySystemAccess) => {
        return keySystemAccess.createMediaKeys();
      },
    ).then(
      (createdMediaKeys) => {
        return videoElement.setMediaKeys(createdMediaKeys);
      },
    ).catch(
      (error) => {
        console.error('EME: Failed to set up MediaKeys', error);
      },
    );
  }

  async handleEncrypted(event: MediaEncryptedEvent) {
    this.isEncrypted = true;
    const handler = this.handleMessage.bind(this);
    const videoElement = event.target as HTMLVideoElement;
    const session = videoElement.mediaKeys?.createSession('temporary');
    if (session && event.initData) {
      session.addEventListener('message', handler, false);
      await session.generateRequest(event.initDataType, event.initData).catch((error) => {
        console.error('EME: Failed to generate a license request', error);
      });
    }
  }

  async handleMessage(event: MediaKeyMessageEvent) {
    const license = await this.generateLicense(event.message);

    const session = event.target as MediaKeySession;
    if (license) {
      session.update(license).catch((error) => {
        console.error('EME: Failed to update the session', error);
      });
    } else {
      console.error('EME: An error occured while processing the license.');
    }
  }

  async generateLicense(message: ArrayBuffer) {
    // Parse the clearkey license request.
    const request: KeyRequest = JSON.parse(new TextDecoder().decode(message));
    const result = await this.videoService.getKeys(this.videoFile, request);
    return new TextEncoder().encode(JSON.stringify({
      keys: result,
    }));
  }
}
