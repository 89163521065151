// --- DYNAMODB ENUMS ---

/**
 * @description
 * Each enum member maps to a specific attribute name (column) in a DynamoDB table.
 * This enhances type safety, prevents typos, and centralizes attribute names for easier maintenance.
 *
 * @example
 * ```
 * // The enum.
 * export enum KeyDb {
 *   CertSerialNumber = 'certSerialNumber',
 *   Id = 'id',
 *   PrivateKeyPem = 'privateKeyPem',
 *   ...
 * }
 *
 * // Use bracket notation [] when accessing DynamoDB items.
 * const tableSearchParams = {
 *   TableName: tableName,
 *   Key: {
 *     [KeyDb.Id]: { S: 'A6-EQP' },
 *     [KeyDb.CertSerialNumber]: { S: 'certificate' },
 *   },
 * };
 *
 * const item = await ddb.getItem(tableSearchParams).Item;
 * if (!item) { ... }
 * const privateKeyPem = item[KeyDb.PrivateKeyPem]?.S;
 * ```
 */

/**
 * Represents the attribute names for the keys DynamoDB.
 */
export enum KeyDb {
  CertificatePem = 'certificatePem',
  CertSerialNumber = 'certSerialNumber',
  EndTime = 'endTime',
  Id = 'id',
  PrivateKeyPem = 'privateKeyPem',
  StartTime = 'startTime',
  Type = 'type',
}

/**
 * Represents the attribute names for the video meta data DynamoDB.
 */
export enum VideoMetaDb {
  CameraId = 'cameraId',
  EndTime = 'endTime',
  FileName = 'fileName',
  FlightNo = 'flightNo',
  Marked = 'marked',
  StartTime = 'startTime',
  TailNo = 'tailNo',
  Type = 'type',
}

/**
 * Represents the attribute names for the video state DynamoDB.
 */
export enum VideoStateDb {
  AvailableAt = 'availableAt',
  DeletedAt = 'deletedAt',
  Ttl = 'ttl',
  State = 'state',
  UploadedAt = 'uploadedAt',
  ToBeDeletedAt = 'toBeDeletedAt',
}

/**
 * Represents the possible video file states.
 */
export enum VideoStates {
  /**
   * The video is in the process of being uploaded but has not yet completed.
   */
  Uploading = 'uploading',

  /**
   * The file has been successfully uploaded and is now available for use.
   */
  Available = 'available',

  /**
   * The file has been successfully deleted from the system.
   */
  Deleted = 'deleted',

  /**
   * The file will be deleted from the system.
   */
  ToBeDeleted = 'toBeDeleted',
}

/**
 * Represents the name of each key in the metadata table.
 */
export enum VideoMetaDbKeys {
  PrimaryKey = 'primaryKey',
  PartitionKey = 'partitionKey',
  SortKey = 'sortKey',
  // @ts-expect-error - Frontend would complain otherwise.
  GSI1PartitionKey = VideoMetaDb.FileName,
}

/**
 * Represents the file type in the metadata table.
 */
export enum VideoMetaDbTypes {
  Video = 'VIDEO',
  Snap = 'SNAP',
  Live = 'LIVE',
}

/**
 * Represents the name of each GSI and LSI in the metadata table.
 */
export enum VideoMetaDbIndexes {
  FileNameIndex = 'fileNameIndex',
}

/**
 * Represents the attribute names for the ac status DynamoDB.
 */
export enum AcStatusDb {
  TailNo = 'tailNo',
  Ttl = 'ttl',
}

/**
 * Represents the attribute names for the otp DynamoDB.
 */
export enum OtpDb {
  Ticket = 'ticket',
  Ttl = 'ttl',
}

/**
 * Represents the attribute names for the bookmark DynamoDB.
 */
export enum BookmarkDb {
  UserId = 'userId',
  VideoMetaKey = 'videoMetaKey',
}

/**
 * Represents the attribute names for the access log DynamoDB.
 */
export enum AccessLogDb {
  AllowedRoles = 'allowedRoles',
  Permission = 'permission',
  Resource = 'resource',
  SortKey = 'sortKey',
  Ttl = 'ttl',
  UserId = 'userId',
  UserRoles = 'userRoles',
}

/**
 * Represents the attribute names for the toBeDeleted DynamoDB.
 */
export enum ToBeDeletedDb {
  MetadataPartitionKey = 'metadataPartitionKey',
  MetadataSortKey = 'metadataSortKey',
  FileName = 'fileName',
  Ttl = 'ttl',
}

/**
 * Represents the attribute names for the WebSocket connections DynamoDB.
 */
export enum WsConnectionDb {
  Id = 'connectionId',
  Ttl = 'ttl',
}

/**
 * Represents the attribute names for the connection log DynamoDB.
 */
export enum ConnectionLogDb {
  EventType = 'eventType',
  Message = 'message',
  TailNo = 'tailNo',
  Timestamp = 'timestamp',
  Ttl = 'ttl',
}

// --- WEBSOCKET ---

/**
 * Represents the WebSocket message type.
 */
export enum WebSocketMessageTypes {
  AcStatusChange = 'acStatusChange',
  AcToGroundMessage = 'gvsw',
}

// --- CONSTANTS ---

// --- TIME ---

/**
 * Represents one second.
 */
export const ONE_SECOND = 1;

/**
 * Represents one minute in seconds.
 */
export const ONE_MINUTE = ONE_SECOND * 60;

/**
 * Represents one hour in seconds.
 */
export const ONE_HOUR = ONE_MINUTE * 60;

/**
 * Represents one day in seconds.
 */
export const ONE_DAY = ONE_HOUR * 24;

/**
 * Represents one week in seconds.
 */
export const ONE_WEEK = ONE_DAY * 7;

// --- METADATA TABLE ---

/**
 * Gets the Time To Live for files with deleted state.
 */
export const TTL_DELETED = ONE_WEEK;

/**
 * Gets the Time To Live for files with to be deleted state in s3 bucket.
 */
export const TTL_TO_BE_DELETED = ONE_WEEK;

/**
 * Gets the Time To Live for files with to be deleted state in metadatadb.
 */
export const TTL_TO_BE_DELETED_METADATA = ONE_WEEK * 2; // should be higher than TTL_TO_BE_DELETED

/**
 * Gets the Time To Live for files with no or faulty state.
 */
export const TTL_NULL = ONE_HOUR;

/**
 * Gets the Time To Live for files with uploading state.
 */
export const TTL_UPLOADING = ONE_DAY;

/**
 * Gets the delimiter used in the metadata table primary key.
 */
export const METADATA_PRIMARY_KEY_DELIMITER = '#';

// --- TABLE NAMES ---

export const VIDEOMETATABLE_ID = 'videoMetaDB';
export const ACSTATUSTABLE_ID = 'acStatusDB';
export const WSCONNECTIONTABLE_ID = 'wsConnectionDB';
export const OTPTABLE_ID = 'otpDB';
export const BOOKMARKTABLE_ID = 'bookmarkDB';
export const VIDEOBUCKET_ID = 'video-bucket';
export const KEY_DB_ID = 'keysDB';
export const CONNECTION_LOG_ID = 'connectionLogDB';
export const LAMBDA_ACCESS_LOG_TABLE_ID = 'lambdaAccessLogDB';
export const KEYVAULT_LAMBDA_ACCESS_LOG_TABLE_ID = 'keyVaultLambdaAccessLogDB';
export const TO_BE_DELETED_ID = 'toBeDeletedDB';

// --- TABLE REGION ---
// export const WSCONNECTIONTABLE_DB_REGION = targetRegion;

// --- OTHER ---

export const NUMBER_OF_HESU_CONTAINER = 1;
export const HESU_FLIGHT_PREFIX = 'HESU';
export const HESU_TAIL_PREFIX = 'A6';

export const CONNECTION_LOG_TTL_PARAM_NAME = 'connectionLogTtl';

/**
 * The two hosted zones HOSTED_ZONE_DOMAIN and KEYVAULT_HOSTED_ZONE_DOMAIN were created
 * manually in the shared account via the AWS Route 53 Service console.
 *
 * The values ofHOSTED_ZONE_ID and KEYVAULT_HOSTED_ZONE_ID were copied after creation
 * from the AWS Route 53 Service Console and pasted into this file.
 */

export const HOSTED_ZONE_ID = 'Z01503393MZKTD6WKOTQJ';
export const HOSTED_ZONE_DOMAIN = 'cloud.kid.aero';
export const KEYVAULT_HOSTED_ZONE_ID = 'Z03484342W8XC97YFUQBE';
export const KEYVAULT_HOSTED_ZONE_DOMAIN = 'vault.cloud.kid.aero';

export const SANDBOX_UNIQUE_IDENTIFIER = 'sandbox';
export const DEV_UNIQUE_IDENTIFIER = 'dev';
export const TEST_EU_UNIQUE_IDENTIFIER = 'eu-test';
export const TEST_UAE_UNIQUE_IDENTIFIER = 'uae-test';
export const EMIRATES_UNIQUE_IDENTIFIER = 'ek';

export const CUSTOM_ROLE_NAME = 'custom:role';

const devRedirectSignInOut = ['http://localhost:4200', 'https://demo.dev.cloud.kid.aero/'];
const testRedirectSignInOut = ['https://demo.eu-test.cloud.kid.aero/', 'https://demo.uae-test.cloud.kid.aero/'];
const emiratesRedirectSignInOut = ['https://demo.ek.cloud.kid.aero/'];

const devUserPool = {
  userPoolId: 'eu-central-1_3FBon3R5N',
  userPoolClientId: 'hasjub3qgp8fl5v6ieii93s49',
  identityPoolId: 'eu-central-1:f2cbfe6d-4bfa-4fb7-91a2-246d13047f36',
  identityProviderName: 'azureidp',
  redirectSignIn: devRedirectSignInOut,
  redirectSignOut: devRedirectSignInOut,
  authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3FBon3R5N',
};

const testUserPool = {
  userPoolId: 'eu-central-1_SIDg2dra0',
  userPoolClientId: '2lc9370kdt4gqvs6sm0e14mkmh',
  identityPoolId: 'eu-central-1:f0c7a8a0-db59-429f-aa8d-2c2cf7703358',
  identityProviderName: 'azureidp',
  redirectSignIn: testRedirectSignInOut,
  redirectSignOut: testRedirectSignInOut,
  authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_SIDg2dra0',
};

export const COGNITO: {
  [uniqueIdentifier: string]: {
    userPoolId: string,
    userPoolClientId: string,
    identityPoolId?: string,
    identityProviderName?: string,
    redirectSignIn?: string[],
    redirectSignOut?: string[],
    authority: string,
  },
} = {
  [DEV_UNIQUE_IDENTIFIER]: devUserPool,
  [SANDBOX_UNIQUE_IDENTIFIER]: devUserPool,
  [TEST_EU_UNIQUE_IDENTIFIER]: testUserPool,
  [TEST_UAE_UNIQUE_IDENTIFIER]: testUserPool,
  test: testUserPool,
  [EMIRATES_UNIQUE_IDENTIFIER]: {
    userPoolId: 'eu-central-1_E1wtbkSCs',
    userPoolClientId: '7i21chu8mq1ho2khg5ms22aoua',
    identityPoolId: 'eu-central-1:77ca2f61-5957-458b-b672-c85bec0228f1',
    identityProviderName: 'azureidp',
    redirectSignIn: emiratesRedirectSignInOut,
    redirectSignOut: emiratesRedirectSignInOut,
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_E1wtbkSCs',
  },
};

export const Pipeline = {
  app: 'AppSstStackPipeline',
  key: 'KeySstStackPipeline',
  frontend: 'FrontendStackPipeline',
  preResources: 'PreResourcesStackPipeline',
  live: 'LiveStreamStackPipeline',
};

export const rtspsPort = 8322;
export const toCloudStreamPort = rtspsPort;
export const toCloudStreamProtocol = 'rtsps';

export const AWS_REGION_EU_1 = 'eu-central-1';
export const AWS_REGION_UAE_1 = 'me-central-1';

export const ttlAcStatus = ONE_DAY;
export const ttlConnectionLogDefault = ONE_DAY * 30;

export const regionShort = {
  'eu-central-1': 'eu1',
  'me-central-1': 'uae1',
} as const;

export const regionShortLabel = {
  'eu-central-1': 'EU1',
  'me-central-1': 'UAE1',
} as const;

export const accountIdentifiers = [
  DEV_UNIQUE_IDENTIFIER,
  TEST_UAE_UNIQUE_IDENTIFIER,
  TEST_EU_UNIQUE_IDENTIFIER,
  EMIRATES_UNIQUE_IDENTIFIER,
];

export type Stages = 'sandbox' | 'dev' | 'test' | 'prod';
