import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TicketService } from './ticket.service';
import { WebsocketMessageHandlerService } from './websocket-message-handler.service';
import { StorageService } from './storage.service';

const WSS_URL = environment.websocket;

/**
 * Handles WebSocket setup, connection and disconnection.
 *
 * To handle incoming WebSocket messages, use the `WebsocketMessageHandlerService` class.
 */
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private subject: Subject<MessageEvent> | undefined;
  private subscription = new Subscription();
  private websocket: WebSocket | undefined;

  constructor(
    private storageService: StorageService,
    private ticketService: TicketService,
    private websocketMessages: WebsocketMessageHandlerService,
  ) {
  }

  /**
   * Establishes a WebSocket connection.
   */
  connect(): void {
    if (this.websocket)
      return;

    const username = this.storageService.getUsername();
    this.ticketService.requestTicket(username).subscribe({
      next: response => this.setup(response),
      error: (e: Error) => console.error(e),
    });
  }

  /**
   * Terminates the WebSocket connection.
   */
  disconnect(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = new Subscription();
      this.subject = undefined;
    }
    if (this.websocket) {
      this.websocket.close();
      this.websocket = undefined;
    }
  }

  /**
   * Sets up a WebSocket.
   *
   * @param ticket The OTP ticket needed to establish a connection.
   */
  private setup(ticket: string): void {
    const wssUrl = `${WSS_URL}?otp=${ticket}`;
    this.websocket = new WebSocket(wssUrl);
    this.subject = new Subject<MessageEvent>();

    this.websocket.onopen = () => {
      console.log('Websocket connected');
      if (this.websocket)
        this.websocket.send(JSON.stringify({ action: 'sendmessage', data: 'hello Server!' }));
    };
    this.websocket.onmessage = (message) => {
      if (this.subject)
        this.subject.next(message);
      this.websocketMessages.handleMessage(message);
    };
    this.websocket.onerror = error => console.error('WebSocket error:', error);
    this.websocket.onclose = () => {
      if (this.subject)
        this.subject.complete();
      console.log('Websocket disconnected');
      this.websocket = undefined;
    };

    this.subscription.add(() => {
      if (this.websocket) {
        this.websocket.close();
        this.websocket = undefined;
      }
    });
  }
}
