import { Component, OnInit } from '@angular/core';
import { MediaFile } from 'src/app/shared/types/videofile';
import { MediaService } from '../../../shared/service/media.service';

@Component({
  selector: 'app-video-screen',
  templateUrl: './video-screen.component.html',
  styleUrls: ['./video-screen.component.scss'],
})
export class VideoScreenComponent implements OnInit {
  screens: MediaFile[] = [];
  audioCapture!: boolean;

  constructor(private videoService: MediaService) {
    videoService.audioCapture$.subscribe({
      next: (status) => {
        this.audioCapture = status;
      },
      error: (e: Error) => console.error(e),
    });
  }

  ngOnInit() {
    this.screens = this.videoService.selectedVideoFiles;
  }

  checkMultiScreen() {
    return this.screens.length <= 1
      ? 'single-screen'
      : this.screens.length <= 4
        ? 'multi-screen'
        : 'much-more-screen';
  }

  sendAudioTopic() {
    const command = !this.audioCapture;
    this.videoService.sendAudioCaptureCommand(command).subscribe({
      next: () => {
        console.log('Sending command for audio capture:', command);
      },
      error: (error: Error) => {
        console.error('Error ', error);
      },
    });
  }

  getToggle() {
    return this.audioCapture ? 'mute' : 'unmute';
  }
}
