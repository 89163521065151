<div>
    <!-- <h3>Camera's</h3>
    <mat-list>
        <mat-list-item *ngFor="let item of videoItems" [ngClass]="checkSelected(item.tailNo.S)"
            (click)="startVideo(item.tailNo.S)" class="main-item">
            <span>
                {{ item.tailNo.S }}
            </span>
        </mat-list-item>
        <mat-list-item [ngClass]="checkSelected('SomeVideo_Transcoded_libx264_crf_23.mp4')"
            (click)="startVideo('SomeVideo_Transcoded_libx264_crf_23.mp4')" class="main-item">
            Transcoded Clip
        </mat-list-item>
        <mat-list-item (click)="websocket.disconnect()" class="main-item">Disconnect</mat-list-item>
    </mat-list>
    <h3>AC Status</h3>
    <mat-list>
        <mat-list-item *ngFor="let item of dummyState | keyvalue" class="status-item">
            <span>
                <b>{{ item.key }}</b>
                <br>
                {{ item.value }}
            </span>
        </mat-list-item>
    </mat-list> -->
</div>