import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AircraftStatus } from '../types/acstatus';
import { ttlAcStatus } from '../../../../../core/constants';

@Injectable({
  providedIn: 'root',
})
export class AcStatusService {
  acstatus: AircraftStatus[] = [];
  acStatusBehavior = new BehaviorSubject<AircraftStatus[]>(this.acstatus);

  constructor(protected http: HttpClient) {
  }

  public initializeStatusList() {
    const initialStatusStr = this.getInitialAcStatus();
    this.parseAcStatus(initialStatusStr);
  }

  private getInitialAcStatus(): Observable<string> {
    const url = `${environment.api}/requestACStatus`; // stage dev || prod && Replace with your API endpoint URL
    return this.http.get(url, { responseType: 'text' });
  }

  private parseAcStatus(initialStatusStr: Observable<string>) {
    initialStatusStr.subscribe(
      (str) => {
        this.acstatus = [];
        const jsonArr: any = JSON.parse(str);
        jsonArr.forEach((item: any) => {
          const acStatus = this.createACInfoEntry(item.ac_info, item.ttl);
          this.acstatus.push(acStatus);
        });
        this.acStatusBehavior.next(this.acstatus);
      },
    );
  }

  public updateAcStatus(acInfo: any, ttl: number) {
    const acStatus = this.createACInfoEntry(acInfo, ttl);
    if (acStatus.TailNo) {
      this.acstatus = this.acstatus.filter(obj => obj.TailNo !== acStatus.TailNo);
      this.acstatus.push(acStatus);
      this.acStatusBehavior.next(this.acstatus);
    }
  }

  public removeAcStatus(tailNo: string) {
    this.acstatus = this.acstatus.filter(obj => obj.TailNo !== tailNo);
    this.acStatusBehavior.next(this.acstatus);
  }

  private createACInfoEntry(acInfo: any, ttl: number) {
    const acStatus: AircraftStatus = {
      TailNo: acInfo.tailNo,
      FlightNo: acInfo.flightNo,
      Alert: acInfo.alert ? 'check' : 'close',
      ConnectivityType: this.parseConnectivityType(acInfo.connectivityType),
      LastModified: new Date(1000 * (ttl - ttlAcStatus)).toISOString(),
    };
    return acStatus;
  }

  private parseConnectivityType(value: string): string {
    if (value.toLowerCase() === 'gatelink') {
      return 'wifi';
    } else if (value.toLowerCase() === 'sat') {
      return 'satellite_alt';
    } else if (value.toLowerCase() === 'offline') {
      return 'airplanemode_inactive';
    } else if (value.toLowerCase() === '4g') {
      return 'signal_cellular_alt';
    } else if (value.toLowerCase() === 'fake') {
      return 'cloud_sync';
    } else {
      return 'cell_wifi';
    }
  }
}
