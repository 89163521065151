import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './authentification/components/login/login.component';
import { HomeComponent } from './homeScreen/home/home.component';
import { AcStatusComponent } from './acStatusScreen/ac-status/ac-status.component';
import { MediaListComponent } from './mediaListScreen/media-list.component';
import { AuthGuard } from './shared/service/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'video', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'acinfo', component: AcStatusComponent, canActivate: [AuthGuard] },
  { path: 'videolist', component: MediaListComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
