<div class="display-flex">
    <div class="video-screen">
        <div [ngClass]="checkMultiScreen()" *ngFor="let item of screens; let i = index">
            <!-- <div class="single-screen" *ngFor="let item of screens; let i = index"> -->
            <iframe class="iframe-cont" *ngIf="item.MediaType === 'videocam'" [src]="item.url" scrolling="no"></iframe>
            <app-video-player *ngIf="item.MediaType === 'video_library'" [videoFile]="item" />
            <span class="video-number">{{i + 1}}</span>
        </div>
        <button class="mute-button" mat-raised-button (click)="sendAudioTopic()">{{getToggle()}}</button>
    </div>
</div>