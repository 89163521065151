import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const AUTH_TOKEN_COOKIE_NAME = 'jwtAccessToken';
const USERNAME_COOKIE_NAME = 'username';

/**
 * Manages cookies and local storage.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private cookieService: CookieService,
  ) { }

  /**
   * Gets the current username.
   *
   * @returns The current username.
   */
  getUsername(): string {
    return this.cookieService.get(USERNAME_COOKIE_NAME);
  }

  /**
   * Sets the username.
   *
   * @param username The username.
   * @param expires The expiration time for the cookie that stores the username.
   * In Unix time.
   */
  setUsername(username: string, expires: number): void {
    this.setCookie(USERNAME_COOKIE_NAME, username, expires);
  }

  /**
   * Gets the current JWT access token for authentication.
   *
   * @returns The JWT token.
   */
  getJwtAccessToken(): string | undefined {
    return this.cookieService.get(AUTH_TOKEN_COOKIE_NAME);
  }

  /**
   * Sets the JWT access token used for authentication.
   *
   * @param token The JWT token to be used for subsequent requests.
   * @param expires The expiration time. In Unix time.
   */
  setJwtAccessToken(token: string, expires: number): void {
    this.setCookie(AUTH_TOKEN_COOKIE_NAME, token, expires);
  }

  /**
   * Creates a cookie using secure options.
   *
   * @param name The name of the cookie.
   * @param data The data that the cookie stores.
   * @param expires The optional expiration time. In Unix time.
   */
  private setCookie(name: string, data: string, expires?: number): void {
    this.cookieService.set(name, data, { expires, sameSite: 'Strict', secure: true });
  }
}
