import { DEV_UNIQUE_IDENTIFIER, HOSTED_ZONE_DOMAIN, KEYVAULT_HOSTED_ZONE_DOMAIN } from '../../../core/constants';

const uniqueId = DEV_UNIQUE_IDENTIFIER;

export const environment = {
  production: false,
  stage: 'dev',
  uniqueIdentifier: uniqueId,
  api: `https://app.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
  keyVault: `https://${uniqueId}.${KEYVAULT_HOSTED_ZONE_DOMAIN}`,
  live: `https://live.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
  websocket: `wss://wss-app.${uniqueId}.${HOSTED_ZONE_DOMAIN}`,
};
