import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  private token: string | undefined;
  constructor(
    private storageService: StorageService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('.s3.')) {
      this.token = this.storageService.getJwtAccessToken();

      // Clone the request and add the Authorization header
      const modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      // Pass the modified request to the next handler
      return next.handle(modifiedRequest);
    } else {
      return next.handle(request);
    }
  }
}
