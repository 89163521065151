import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, of, startWith, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeyRequest } from 'src/app/video-player/video-player.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MediaFile } from '../types/videofile';
import { MediaListResponse } from '../types/videolistresponse';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private screensSubject: Subject<MediaFile[]> = new Subject<MediaFile[]>();
  screens$: Observable<MediaFile[]> = this.screensSubject.asObservable();
  videoUrl: SafeResourceUrl | undefined;
  currentTailNo = '';
  tailNoRelevantMediaList: MediaFile[] = [];
  mediaListBehavior = new BehaviorSubject<MediaFile[]>(this.tailNoRelevantMediaList);
  selectedCount = 0;
  selectedVideoFiles: MediaFile[] = [];
  private audioCapture: Subject<boolean> = new Subject<boolean>();
  audioCapture$: Observable<boolean> = this.audioCapture.pipe(startWith(false));

  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  getMediaList(selTailNo: string): Observable<any> {
  // ToDo_ CodeReview_1 fernhoer: Also maybe add further parameters?
  // Decision: do filter parameters later
    this.currentTailNo = selTailNo;
    const url = `${environment.api}/getMetadata`;
    const params = {
      tailNo: this.currentTailNo,
      // type: 'VIDEO',  // get all media! Videos and images!
    };
    return this.http.get<MediaFile[]>(url, { params });
  }

  loadMediaList(selTailNo: string): void {
    this.tailNoRelevantMediaList = [];
    this.selectedCount = 0;

    this.getMediaList(selTailNo).pipe(
      map((response: MediaListResponse) => {
        const mediaFiles = response.Items || [];
        return mediaFiles
          .filter(mediaFile => mediaFile && mediaFile.tailNo && mediaFile.tailNo.S === selTailNo)
          .map(mediaFile => this.parseMetaData(mediaFile, selTailNo));
      }),
      catchError((error) => {
        console.error('Error fetching video files:', error);
        return throwError(error);
      }),
    ).subscribe({
      next: (mediaList: MediaFile[]) => {
        this.tailNoRelevantMediaList = mediaList;
        this.mediaListBehavior.next(this.tailNoRelevantMediaList);
      },
      error: (error) => {
        console.error('Error in subscription:', error);
      },
      complete: () => {
        console.info('MediaList: Subscription complete');
      },
    });
  }

  parseMetaData(item: any, tailNo: string): MediaFile {
    let endTime = '';
    let marked = '';

    if (item.endTime) {
      if (item.startTime.N < item.endTime) {
        endTime = new Date(item.endTime.N * 1000).toISOString();
      }
    }

    if (item?.marked !== undefined) {
      if (item.marked.BOOL) {
        marked = 'check';
      }
    }

    const vf: MediaFile = {
      tailNo,
      FileName: item.fileName.S,
      FlightNo: item.flightNo.S,
      CameraID: item.cameraId.S,
      StartTime: new Date(item.startTime.N * 1000).toISOString(),
      EndTime: endTime,
      MediaType: this.parseMediaType(item.type.S),
      Marked: marked,
      encryptedEnvelope: item.encryptedEnvelope ? item.encryptedEnvelope.S : '',
      keys: '',
      Selected: false,
    };
    return vf;
  }

  downloadVideo(fileName: string): Observable<any> {
    const url = `${environment.api}/videoDownloadUrl?key=${fileName}`; // stage dev || prod && Replace with your API endpoint URL
    return this.http.get(url, { responseType: 'text' });
  }

  async getKeys(mediaFile: MediaFile, request: KeyRequest) {
    const url = `${environment.keyVault}/decrypt`; // stage dev || prod && Replace with your API endpoint URL
    return await this.http.post<string>(url, { tailNo: mediaFile.tailNo, encryptedEnvelope: mediaFile.encryptedEnvelope, keyIds: request.kids }).toPromise();
  }

  getVideo(videoFile: MediaFile): Observable<SafeResourceUrl> {
    if (videoFile.MediaType === 'videocam') {
      const url = `${environment.live}/${videoFile.FileName}`;
      this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      return of(this.videoUrl);
    } else {
      return this.downloadVideo(videoFile.FileName).pipe(
        map((videoData: string) => {
          this.videoUrl = videoData;
          return this.videoUrl;
        }),
        catchError((error: Error) => {
          console.error('An error occurred during get video:', error);
          throw error;
        }),
      );
    }
  }

  public select(fileName: string): void {
    this.tailNoRelevantMediaList = this.tailNoRelevantMediaList.map((video) => {
      if (video.FileName === fileName) {
        video.Selected = !video.Selected;
      }
      return video;
    });
    this.selectedVideoFiles = this.tailNoRelevantMediaList.filter((video) => {
      return video.Selected;
    });
    this.selectedCount = this.selectedVideoFiles.length;
    this.selectedVideoFiles.forEach((video) => {
      this.getVideo(video).subscribe({
        next: (url: SafeResourceUrl) => {
          video.url = (url);
        },
      });
    });
  }

  private parseMediaType(value: string): string {
    if (value.toUpperCase() === 'SNAP') {
      return 'photo_camera';
    } else if (value.toUpperCase() === 'LIVE') {
      return 'videocam';
    } else {
      return 'video_library';
    }
  }

  sendAudioCaptureCommand(audio: boolean): Observable<any> {
    const toggle = audio ? 'enable' : 'disable';
    const url = `${environment.api}/ac-command/cmd/${this.currentTailNo}/audio-capture/${toggle}`;
    const body = {};
    return this.http.post(url, body);
  }

  changeAudioStatus(status: boolean) {
    this.audioCapture.next(status);
  }
}
