<div *ngIf="renderHeader()" class="header-container">
    <mat-toolbar class="default-header">
      <mat-toolbar-row>
        <img src="/assets/FTI_Logo.png" alt="FTI Logo">
        <div class="header-text">Genius-Viewer</div>
        <div class="user-section">
          <div class="username">{{ username }}</div>
          <app-menu class="app-menu"></app-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
