import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import * as crypt from '@genius-security/geniuscrypt';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaFile } from '../shared/types/videofile';

export interface IParams {
  image: MediaFile;
  dialog: MatDialog;
}

interface KeyToken {
  kty: string;
  alg: string;
  kid: string;
  k: string;
}
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  animations: [
    trigger('divState', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(0) scale(0.5)',
        }),
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'translateX(0) scale(1)',
        }),
      ),
      transition(':enter', animate('300ms ease-in')),
      transition(':leave', animate('100ms ease-out')),
    ]),
  ],
})

export class ImageViewerComponent implements AfterViewInit {
  imageUrl = '';
  private cr: crypt.GeniusCrypt;
  imageSafeUrl: SafeUrl | undefined;
  blobString = '';

  constructor(
    public dialog: MatDialog,
    protected httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: IParams,
  ) {
    this.cr = new crypt.GeniusCrypt();
    console.log ('##### dataTitle: ', data.image.FileName);
  }

  async ngAfterViewInit(): Promise<void> {
    this.getPresignedUrl(this.data.image.FileName).subscribe(async (imageUrl: string) => {
      this.imageUrl = imageUrl;
      console.log('async url: ', this.imageUrl);
      const blobData = await this.getImageData(this.imageUrl);
      const blobArray = await blobData.arrayBuffer();
      const keyStr = String.fromCharCode(...new Uint8Array(blobArray.slice(0, 16)));
      let key: KeyToken[] | undefined;
      try {
        key = await this.getKeys(this.data.image, this.toBase64(this.cr.toHex(keyStr)));
      } catch (error) {
        console.log('No decryption key. Try loading unencrypted image!');
      }

      if (key) {
        const fk: crypt.FileKey = {
          audioId: '',
          audioKey: '',
          envelope: '',
          videoId: keyStr,
          videoKey: atob(key[0].k.replace(/\-/g, '+').replace(/\_/g, '/')),
        };

        const decryptedFile = this.cr.decryptFile(fk, blobArray.slice(16));
        const imageBlob = new Blob([this.convertBinaryStringToUint8Array(decryptedFile)], { type: 'image/jpg' });
        const blobUrl = URL.createObjectURL(imageBlob);
        this.imageSafeUrl = this.domSanitizer.bypassSecurityTrustUrl(blobUrl);
      } else {
        const blobUrl = URL.createObjectURL(blobData);
        this.imageSafeUrl = this.domSanitizer.bypassSecurityTrustUrl(blobUrl);
      }
    });
  }

  getPresignedUrl(fileName: string): Observable<any> {
    const url = `${environment.api}/videoDownloadUrl?key=${fileName}`; // stage dev || prod && Replace with your API endpoint URL
    console.log('requesturl: ', url);
    return this.http.get(url, { responseType: 'text' });
  }

  async getImageData(s3PresignedUrl: string): Promise<Blob> {
    const resp = await fetch(s3PresignedUrl);
    const blob = await resp.blob();
    return blob;
  }

  async getKeys(imageFile: MediaFile, keyId: string) {
    const url = `${environment.keyVault}/decrypt`; // stage dev || prod && Replace with your API endpoint URL
    console.log('decrypt url: ', url);
    console.log('tailNo: ', imageFile.tailNo);
    console.log('encryptedEnvelope: ', imageFile.encryptedEnvelope);
    console.log('keyId: ', keyId);
    const rtn = await this.http.post<KeyToken[]>(url, { tailNo: imageFile.tailNo, encryptedEnvelope: imageFile.encryptedEnvelope, keyIds: [keyId] }).toPromise();
    console.log('rtn: ', rtn);
    return rtn;
  }

  convertBinaryStringToUint8Array(bStr: any): Uint8Array {
    const len = bStr.length;
    const u8Array = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      u8Array[i] = bStr.charCodeAt(i);
    }
    return u8Array;
  }

  /**
   * encodes a hex string using base64url scheme
   *
   * @async
   * @param hexstring hexadecimal string, size must be multiple of 2
   * @returns base64url encoded string
   */
  toBase64(hexstring: string) {
    return btoa(hexstring).replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/, '');
  }
}
