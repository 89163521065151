import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Hub } from '@aws-amplify/core';
import { fetchAuthSession, getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { COGNITO } from '../../../../../core/constants';
import { throwExpression } from '../utils/utils';
import { StorageService } from './storage.service';
import { WebsocketService } from './websocket.service';

/**
 * Handles authentication like login and logout.
 *
 * The authentication flow:
 * ```
 * LoginComponent.login()             | Frontend
 * -> authService.logIn()             | Frontend
 * -> signInWithRedirect()            | Amplify
 * -> Microsoft Azure OAuth           | Azure
 * -> Hub.listen: signInWithRedirect  | Amplify
 * -> Hub.listen: signedIn            | Amplify
 * -> authService.handleSignIn()      | Frontend
 * ```
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private websocket: WebsocketService,
  ) {
    Hub.listen('auth', this.handleAuthEvent.bind(this));
  }

  // ----- Public Methods -----

  /**
   * Gets whether a user is currently logged in.
   *
   * @returns A promise that resolves to `true` if logged in, `false` otherwise.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Logs information about the currently authenticated user to the console.
   */
  async logCurrentUser(): Promise<void> {
    try {
      const user = await getCurrentUser();
      console.log({ user });
    } catch (error) {
      console.log('No user authenticated.');
    }
  }

  /**
   * Initiates the login process.
   *
   * @link [Custom provider docs](https://docs.amplify.aws/angular/build-a-backend/auth/add-social-provider/#set-up-your-frontend)
   */
  async logIn(): Promise<void> {
    try {
      const providerName = COGNITO[environment.uniqueIdentifier].identityProviderName
        || throwExpression('No \'identityProviderName\' set for this stage.', 'NoIdentityProviderName');

      await signInWithRedirect({
        provider: {
          custom: providerName,
        },
      });
    } catch (error) {
      console.error('Failed to login:', error);
      throw error;
    }
  }

  /**
   * Logs out the current user.
   */
  async logOut(): Promise<void> {
    try {
      await signOut();
      // Other sign out logic is handled via 'signedOut' from 'Hub.listen'.
    } catch (error) {
      console.error('Failed to sign out user.');
      throw error;
    }
  }

  /**
   * Logs out the current user and immediately attempts to log them back in.
   */
  async reLogIn(): Promise<void> {
    await this.logOut();
    await this.logIn();
  }

  // ----- Private Methods -----

  /**
   * Gets the expiration time for the provided JWT token.
   *
   * @param token The JWT token.
   * @returns The expiration time. In Unix time.
   */
  private getTokenExpiration(token: string): number {
    const decodedToken = jwtDecode<{ exp?: number, }>(token);
    const expires = decodedToken?.exp || new Date().getTime() + 60 * 60 * 1000;

    return expires;
  }

  /**
   * Handles authentication events from the AWS Amplify Hub.
   * Listens for authentication events and triggers the appropriate actions.
   *
   * @link [Amplify Documentation](https://docs.amplify.aws/angular/build-a-backend/auth/auth-events/)
   */
  private async handleAuthEvent(): Promise<void> {
    Hub.listen('auth', ({ payload }) => {
      console.log('Auth event:', payload.event);

      switch (payload.event) {
        case 'signedIn':
          this.handleSignIn();
          break;
        case 'signedOut':
          this.handleSignOut();
          break;
        case 'tokenRefresh':
          this.reLogIn();
          break;
        case 'tokenRefresh_failure':
          // Token refresh is not available for SSO, so a token refresh fails.
          this.reLogIn();
          break;
        case 'signInWithRedirect':
          // Handled in 'signedIn'.
          break;
        case 'signInWithRedirect_failure':
          this.router.navigate(['']);
          break;
        case 'customOAuthState':
          break;
        default:
          break;
      }
    });
  }

  /**
   * Handles tasks after a user login.
   */
  private async handleSignIn(): Promise<void> {
    try {
      const { username } = await getCurrentUser() || throwExpression('username not found');
      const { tokens } = await fetchAuthSession();

      // The accessToken is more secure than the idToken (see FKID-1101).
      const jwtAccessToken = tokens?.accessToken?.toString() || throwExpression('jwtToken not found.');
      const tokenExpiration = this.getTokenExpiration(jwtAccessToken);
      this.storageService.setJwtAccessToken(jwtAccessToken, tokenExpiration);
      this.storageService.setUsername(username, tokenExpiration);
      this.router.navigate(['/acinfo']);

      console.log('User signed in.');
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  /**
   * Handles tasks after a user logout.
   */
  private handleSignOut(): void {
    this.websocket.disconnect();
    this.router.navigate(['']);
  }
}
