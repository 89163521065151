import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MediaFile } from '../types/videofile';

@Injectable({
  providedIn: 'root',
})
export class AcVideoService {
  initialized = false;
  currentTailNo = '';
  mediaList: MediaFile[] = [];
  selectedCount = 0;
  selectedVideoFiles: MediaFile[] = [];
  mediaListBehavior = new BehaviorSubject<MediaFile[]>(this.mediaList);

  constructor(
    private http: HttpClient,
  ) {}

  updateAcVideoList(mediaList: any) {
    // this.mediaList.push(mediaList.map((value: any) => {
    //   const vf = this.parseMetaData(value, this.currentTailNo);
    //   return vf;
    // }));
    mediaList.map((value: any) => {
      const vf = this.mediaList.push(this.parseMetaData(value, this.currentTailNo));
      return vf;
    });

    this.mediaListBehavior.next(this.mediaList);
    this.initialized = true;
  }

  getVideoList(selTailNo: string, params: any): Observable<any> {
    this.currentTailNo = selTailNo;
    const url = `${environment.api}/ac-command/cmd/${selTailNo}/list-recordings/42`;
    return this.http.post<MediaFile[]>(url, params);
  }

  loadVideoList(selTailNo: string, params: any, cameraList: any[]): void {
    this.initialized = false;
    this.mediaList = [];
    this.selectedCount = 0;
    if (cameraList && (cameraList.length > 0)) {
      cameraList.forEach((cam) => {
        params = { ...params, cameraId: cam.id };
        this.getVideoList(selTailNo, params).subscribe();
      });
    } else {
      this.getVideoList(selTailNo, params).subscribe();
    }
  }

  triggerUploadToCloud() {
    const url = `${environment.api}/ac-command/cmd/${this.currentTailNo}/http/42`;
    const params: any = {
      method: 'post',
      url: '/ac/vss/transfers/request',
      type: 'file',
      action: 'start',
    };

    this.selectedVideoFiles.forEach((videoFile) => {
      params.fileName = videoFile.FileName;
      return this.http.post(url, params).subscribe();
    });
  }

  parseMetaData(item: any, tailNo: string): MediaFile {
    const vf: MediaFile = {
      tailNo,
      FileName: item.fileName,
      FlightNo: item.flightNo,
      CameraID: item.cameraId,
      StartTime: new Date(item.startTime).toISOString(),
      EndTime: (item.endTime && item.startTime !== item.endTime) ? new Date(item.endTime).toISOString() : '',
      MediaType: this.parseMediaType(item.type),
      Marked: item.marked ? 'check' : '',
      encryptedEnvelope: item.encryptedEnvelope ? item.encryptedEnvelope : '',
      keys: '',
      Selected: false,
    };
    return vf;
  }

  public select(fileName: string): void {
    this.mediaList = this.mediaList.map((video) => {
      if (video.FileName === fileName) {
        video.Selected = !video.Selected;
      }
      return video;
    });

    this.selectedVideoFiles = this.mediaList.filter((video) => {
      return video.Selected;
    });

    this.selectedCount = this.selectedVideoFiles.length;
  }

  private parseMediaType(value: string): string {
    if (value && value.toLowerCase() === 'video') {
      return 'video_library';
    } else if (value && value.toLowerCase() === 'image') {
      return 'photo_camera';
    } else {
      return '';
    }
  }

  public clearSelection(): void {
    this.mediaList.forEach((video) => {
      video.Selected = false;
    });

    this.selectedVideoFiles = [];
    this.selectedCount = this.selectedVideoFiles.length;
  }
}
