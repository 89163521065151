<div class="container">
  <div class="language-links">
    <a (click)="translate.use('de')">DE</a>
    <a (click)="translate.use('en')">EN</a>
  </div>
  <img src="/assets/FTI_Logo.png" alt="FTI Logo" class="logo">
  <div class="header-text">Genius-Viewer</div>
  <div [class.visible]="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  <button mat-raised-button color="primary" class="login-btn" type="submit" [innerHTML]="'login' | translate"
    (click)="logIn()"></button>
</div>