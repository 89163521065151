import { Component, OnInit, ViewChild } from '@angular/core';
import { AircraftStatus } from 'src/app/shared/types/acstatus';
import { AcStatusService } from 'src/app/shared/service/ac-status.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/shared/service/media.service';
import { WebsocketService } from 'src/app/shared/service/websocket.service';

@Component({
  selector: 'app-ac-status',
  templateUrl: './ac-status.component.html',
  styleUrls: ['./ac-status.component.scss'],
})
export class AcStatusComponent implements OnInit {
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  displayedColumns = ['Alert', 'TailNo', 'FlightNo', 'ConnectivityType', 'LastModified'];
  private aircraftStatus: AircraftStatus[] = [];
  public dataSource: any; // Wird in ngOnInit initialisiert, nicht Constructor
  public title = 'Aircraft Status List';

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private acStatusService: AcStatusService,
    private videoService: MediaService,
    private websocket: WebsocketService,
  ) { }

  ngOnInit(): void {
    this.acStatusService.initializeStatusList();
    this.acStatusService.acStatusBehavior.subscribe((value) => {
      this.aircraftStatus = value;
      this.dataSource = new MatTableDataSource(this.aircraftStatus);
      if (this.sort) {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property: string) => {
          if (property === 'TailNo') {
            return item.TailNo;
          } else {
            return item.Alert;
          }
        };
        this.sort.sort({ id: 'TailNo', start: 'asc', disableClear: true });
      }
    });
    this.websocket.connect();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      await this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      await this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async onRowClick(row: any) {
    this.videoService.loadMediaList(row.TailNo);
    await this.router.navigate(['videolist']);
  }
}
