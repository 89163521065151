<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon class="material-icons" fontIcon="menu">M</mat-icon>
  <!-- <mat-icon>more_vert</mat-icon> -->
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="navigateToAcInfoList()">
    <span>AC Status List</span>
  </button>
  <button mat-menu-item (click)="reLogin()">
    <span>Re-Login</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <span>Logout</span>
  </button>
</mat-menu>