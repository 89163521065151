import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
/**
 * A guard for routing that ensures the user is authenticated.
 * Redirects unauthenticated users to the root route.
 */
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  /**
   * Determines if a route can be activated based on user authentication.
   *
   * @param route The current route snapshot.
   * @param state The router state snapshot.
   * @returns A promise that resolves to `true` if the user is authenticated,
   * `false` otherwise.
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const isAuthenticated = await this.authService.isLoggedIn();

    if (!isAuthenticated) {
      console.error(`Unauthorized access attempt to a protected route: ${route.url}. You need to login.`);
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
}

/**
 * CanActivateFn implementation that uses the AuthGuard to determine route activation.
 *
 * @example
 * app-routing.module.ts:
 *
 * { path: 'acinfo', component: AcStatusComponent, canActivate: [AuthGuard] },
 *
 * @param route The current route snapshot.
 * @param state The router state snapshot.
 * @returns A promise resolving to `true` if the route can be activated,
 * `false` otherwise.
 */
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthGuardService).canActivate(route, state);
};
