import { Injectable } from '@angular/core';
import { AcStatusChangeEventType } from '../../../../../core/common';
import { WebSocketMessageTypes } from '../../../../../core/constants';
import { AcStatusService } from './ac-status.service';
import { MediaService } from './media.service';
import { AcCameraService } from './ac-camera-list.service';
import { AcVideoService } from './ac-video.service';

/**
 * Handles WebSocket messages.
 *
 * To set up a WebSocket, use the `WebsocketService` class.
 */
@Injectable({
  providedIn: 'root',
})
export class WebsocketMessageHandlerService {
  constructor(
    private acStatusService: AcStatusService,
    private mediaService: MediaService,
    private acCameraListService: AcCameraService,
    private acVideoService: AcVideoService,
  ) { }

  /**
   * Processes incoming WebSocket messages.
   *
   * @param message The incoming WebSocket message.
   */
  handleMessage(message: MessageEvent): void {
    const jsonData: any = JSON.parse(message.data);
    const type = jsonData?.type;

    switch (type) {
      case WebSocketMessageTypes.AcStatusChange:
        this.handleAcStatusChange(jsonData);
        break;
      case WebSocketMessageTypes.AcToGroundMessage:
        this.handleAcToGroundMessage(jsonData);
        break;
      default:
        console.warn('Unhandled WebSocket message type:', type);
    }
  }

  /**
   * Handles A/C status changes.
   *
   * @param jsonData The incoming WebSocket message data.
   */
  private handleAcStatusChange(jsonData: { payload: any, eventType: AcStatusChangeEventType, }): void {
    const { eventType, payload } = jsonData;

    if (eventType === 'Modified' || eventType === 'New') {
      this.acStatusService.updateAcStatus(payload.ac_info, payload.ttl);
    } else if (eventType === 'Removed') {
      this.acStatusService.removeAcStatus(payload.tailNo);
    }
  }

  /**
   * Handles A/C to ground messages.
   *
   * @param jsonData The incoming WebSocket message data.
   */
  private handleAcToGroundMessage(jsonData: any): void {
    if ('audioCapture' in jsonData.payload) {
      this.mediaService.changeAudioStatus(jsonData.payload.audioCapture);
    } else if (jsonData.topic.includes('list-cameras')) {
      this.acCameraListService.setCameraList(jsonData.payload.$unknown);
    } else if (jsonData.topic.includes('list-recordings')) {
      this.acVideoService.updateAcVideoList(jsonData.payload.$unknown);
    }
  }
}
