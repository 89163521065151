import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  requestTicket(username: string): Observable<any> {
    const url = `${environment.api}/getOtp`;
    console.log(`Requesting ticket for user: ${username}`);

    const headers = new HttpHeaders({
      User: username,
    });
    return this.http.get(url, { headers });
  }
}
