import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { configureAmplify } from 'src/main';
import { CookieService } from 'ngx-cookie-service';
import { SidebarComponent } from './homeScreen/components/sidebar/sidebar.component';
import { TokenHttpInterceptor } from './shared/service/token-http.interceptor';
import { VideoScreenComponent } from './homeScreen/components/video-screen/video-screen.component';
import { HomeComponent } from './homeScreen/home/home.component';
import { LoginComponent } from './authentification/components/login/login.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AcStatusComponent } from './acStatusScreen/ac-status/ac-status.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './header/menu/menu.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { AcVideoFilterComponent } from './ac-video-filter/ac-video-filter.component';
import { MediaListComponent } from './mediaListScreen/media-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeAmplify(): () => Promise<void> {
  return () => {
    return new Promise((resolve) => {
      configureAmplify();
      resolve();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SidebarComponent,
    VideoScreenComponent,
    AcStatusComponent,
    MediaListComponent,
    HeaderComponent,
    MenuComponent,
    VideoPlayerComponent,
    ImageViewerComponent,
    AcVideoFilterComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatNativeDateModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAmplify,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
