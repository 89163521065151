<div class="base-container">
  <div class="page-header">
    <h1 mat-dialog-title class="h1-settings">{{title}}</h1>
    <div class="page-button-container">
      <div class="left-arrow-container">
        <button class="button-style" mat-icon-button aria-label="icon-button back to ac-status" (click)="backToAcStatus()">
          <mat-icon class="material-icons left-arrow" fontIcon="arrow_circle_left"></mat-icon>
        </button>
      </div>
      <div *ngIf="goToVideoReplay()" class="right-arrow-container">
        Show {{getSelectedCount()}} Videos 
        <button class="button-style" mat-icon-button aria-label="icon-button to continue video replay" (click)="showVideos()">
          <mat-icon class="material-icons right-arrow" fontIcon="arrow_circle_right"></mat-icon>
        </button>
      </div>
      <div *ngIf="renderCloudUploadIcon()" class="right-arrow-container">
        Upload {{getSelectedCount()}} to Cloud 
        <button class="button-style" mat-icon-button aria-label="icon-button to continue video replay" (click)="uploadVideosToCloud()">
          <mat-icon class="material-icons right-arrow" fontIcon="cloud_upload"></mat-icon>
        </button>
      </div>
      <div class="right-arrow-container">
        Media on A/C
        <button class="button-style" mat-icon-button aria-label="icon-button to continue video replay" (click)="showVideosOnAC()">
          <mat-icon class="material-icons right-arrow" fontIcon="flight"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  
    <div>
      <mat-form-field class="filter-container">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter text" #input>
      </mat-form-field>
    </div>

    <div class="scroll-table-container">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">
      
      <ng-container matColumnDef="FlightNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FlightNo"> FlightNo </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}"> {{videofile.FlightNo}} </td>
      </ng-container>
    
      <ng-container matColumnDef="FileName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FileName"> File Name </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}"> {{videofile.FileName}} </td>
      </ng-container>
    
      <ng-container matColumnDef="CameraID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by CameraID"> Camera Identifier </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}"> {{videofile.CameraID}} </td>
      </ng-container>
    
      <ng-container matColumnDef="StartTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by StartTime"> Start Time </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}">{{videofile.StartTime}}</td>
      </ng-container>
    
      <ng-container matColumnDef="EndTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by EndTime"> End Time </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}">{{videofile.EndTime}}</td>
      </ng-container>

      <ng-container matColumnDef="MediaType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by media type"> Media Type </th>
        <td mat-cell *matCellDef="let videofile"><mat-icon class="material-icons" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}" fontIcon={{videofile.MediaType}}></mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="Marked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Marked"> Marked </th>
        <td mat-cell *matCellDef="let videofile" [ngClass]="{'mat-row-live': videofile.MediaType === 'videocam'}"> {{videofile.Marked}} </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [ngClass]="{'mat-row-selected': row.Selected }" (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  </div>
  </div>
  
  