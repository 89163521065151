import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { COGNITO } from '../../core/constants';
import { throwExpression } from './app/shared/utils/utils';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function configureAmplify() {
  console.log('Stage:', environment.stage);
  console.log('UniqueIdentifier:', environment.uniqueIdentifier);

  const stage = environment.stage === 'sandbox' ? 'dev' : environment.stage || throwExpression('stage not found in environment');
  const uniqueId = environment.uniqueIdentifier || throwExpression('uniqueIdentifier not found in environment');

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: COGNITO[uniqueId].userPoolClientId || throwExpression('userPoolClientId not set'),
        userPoolId: COGNITO[uniqueId].userPoolId,
        loginWith: {
          username: true,
          email: false,
          phone: false,
          oauth: {
            domain: `shared-geniuscloud-${stage}.auth.eu-central-1.amazoncognito.com`,
            scopes: ['openid'],
            redirectSignIn: COGNITO[uniqueId].redirectSignIn || ['http://localhost:4200'],
            redirectSignOut: COGNITO[uniqueId].redirectSignOut || ['http://localhost:4200'],
            responseType: 'code',
          },
        },
      },
    },
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
