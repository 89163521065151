<div class="base-container">
  <div class="page-header">
    <h1 mat-dialog-title class="h1-settings">{{title}}</h1>
  </div>
  
  <div>
    <mat-form-field class="filter-container">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Enter text" #input>
    </mat-form-field>
  </div>

  <div class="scroll-table-container">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">
    
      <ng-container matColumnDef="Alert">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by alert"> Alert </th>
        <td mat-cell *matCellDef="let acstatus"><mat-icon class="material-icons" fontIcon={{acstatus.Alert}}></mat-icon></td>
      </ng-container>
    
      <ng-container matColumnDef="TailNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tailNo"> Tail Number </th>
        <td mat-cell *matCellDef="let acstatus"> {{acstatus.TailNo}} </td>
      </ng-container>
    
      <ng-container matColumnDef="FlightNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by flightNo"> Flight Number </th>
        <td mat-cell *matCellDef="let acstatus"> {{acstatus.FlightNo}} </td>
      </ng-container>
    
      <ng-container matColumnDef="ConnectivityType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by conectivity type"> Connectivity Type </th>
        <td mat-cell *matCellDef="let acstatus"><mat-icon class="material-icons" fontIcon={{acstatus.ConnectivityType}}></mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="LastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last modified"> Last Modified [UTC] </th>
        <td mat-cell *matCellDef="let acstatus"> {{acstatus.LastModified}} </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="mat-row-hover" (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  </div>
</div>
  
  