/**
 * The function `throwExpression` throws an error with the provided error message.
 * @param errorMessage - A string that represents the error message to be thrown.
 * @param - An optional name for the error.
 */
export function throwExpression(errorMessage: string, errorName?: string): never {
  const error = new Error(errorMessage);

  if (errorName)
    error.name = errorName;

  throw error;
}
