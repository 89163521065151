import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import 'aws-amplify/auth/enable-oauth-listener';
import { MediaService } from 'src/app/shared/service/media.service';
import { AuthService } from '../../../shared/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  errorMessage = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
    public mediaService: MediaService,
  ) {}

  async logIn() {
    this.errorMessage = '';
    const isLoggedIn = await this.authService.isLoggedIn();

    if (isLoggedIn) {
      await this.router.navigate(['/acinfo']);
      return;
    }

    try {
      await this.authService.logIn();
    } catch (error) {
      this.errorMessage = error instanceof Error
        ? `${this.translate.instant('loginError')}${error.name}`
        : '';
    }
  }
}
