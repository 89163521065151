import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MediaFile } from '../shared/types/videofile';
import { MediaService } from '../shared/service/media.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { AcVideoFilterComponent } from '../ac-video-filter/ac-video-filter.component';
import { AcCameraService } from '../shared/service/ac-camera-list.service';
import { AcVideoService } from '../shared/service/ac-video.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent implements OnInit {
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  displayedColumns = ['FlightNo', 'FileName', 'CameraID', 'StartTime', 'EndTime', 'MediaType', 'Marked'];
  private mediaFileList: MediaFile[] = [];
  public dataSource: any; // Wird in ngOnInit initialisiert, nicht Constructor
  public mediaDataSource: any;
  public acVideoDataSource: any;
  public title = 'Media List';
  private serviceRef: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private acCameraService: AcCameraService,
    private mediaService: MediaService,
    private acVideoService: AcVideoService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.mediaService.mediaListBehavior.subscribe((value) => {
      this.mediaFileList = value;
      this.serviceRef = this.mediaService;
      this.mediaDataSource = new MatTableDataSource(this.mediaFileList);
      this.dataSource = this.mediaDataSource;
      if (this.sort) {
        this.sort.disableClear = true;
        this.mediaDataSource.sort = this.sort;
      }
      if (this.mediaService.currentTailNo) {
        this.title = `Media List - Tail Number: ${this.mediaService.currentTailNo}`;
      } else {
        this.router.navigate(['/acinfo']);
      }
    });

    this.acVideoService.mediaListBehavior.subscribe((value) => {
      this.mediaFileList = value;
      this.acVideoDataSource = new MatTableDataSource(this.mediaFileList);
      this.dataSource = this.acVideoDataSource;
      this.serviceRef = this.acVideoService;
      if (this.sort) {
        this.sort.disableClear = true;
        this.acVideoDataSource.sort = this.sort;
      }
      if (this.mediaService.currentTailNo) {
        this.title = `A/C Media List - Tail Number: ${this.mediaService.currentTailNo}`;
      } else {
        this.router.navigate(['/acinfo']);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      await this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      await this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onRowClick(row: any) {
    if ((this.serviceRef === this.mediaService) && (row.MediaType === 'photo_camera')) {
      const image = this.mediaService.tailNoRelevantMediaList.find(video => (video.FileName === row.FileName));
      if (image)
        this.openImageViewer(image);
    } else {
      this.serviceRef.select(row.FileName);
    }
  }

  goToVideoReplay(): boolean {
    return (this.mediaService.selectedCount > 0) && (this.serviceRef === this.mediaService);
  }

  renderCloudUploadIcon(): boolean {
    return (this.acVideoService.selectedCount > 0) && (this.serviceRef === this.acVideoService);
  }

  getSelectedCount(): string {
    return this.serviceRef.selectedCount.toString();
  }

  async backToAcStatus() {
    if (this.serviceRef === this.mediaService) {
      await this.router.navigate(['/acinfo']);
    } else {
      this.title = `Media List - Tail Number: ${this.mediaService.currentTailNo}`;
      this.acVideoService.clearSelection();
      this.serviceRef = this.mediaService;
      this.dataSource = this.mediaDataSource;
    }
  }

  async showVideos() {
    await this.router.navigate(['/video']);
  }

  uploadVideosToCloud() {
    this.acVideoService.triggerUploadToCloud();
  }

  async showVideosOnAC() {
    this.acCameraService.loadCameraList(this.mediaService.currentTailNo);
    this.dialog.open(AcVideoFilterComponent, {
      backdropClass: 'dialog-scrim-screen-background',
      data: {
        tailNo: this.mediaService.currentTailNo,
        dialog: this.dialog,
      },
    });
  }

  openImageViewer(image: MediaFile) {
    this.dialog.open(ImageViewerComponent, {
      backdropClass: 'dialog-scrim-screen-background',
      data: {
        image,
        dialog: this.dialog,
      },
    });
  }
}
