import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageService } from '../shared/service/storage.service';
import { COGNITO } from '../../../../core/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username: string | undefined;

  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.displayUsername();
  }

  /**
   * Shows the username in the header, removing the prefix added by the
   * identity provider, if necessary.
   */
  displayUsername(): void {
    const prefix = `${COGNITO[environment.uniqueIdentifier].identityProviderName}_`;
    const userWithPrefix = this.storageService.getUsername();
    this.username = userWithPrefix.replace(prefix, '');
  }

  renderHeader(): boolean {
    return this.router.routerState.snapshot.url !== '/';
  }
}
