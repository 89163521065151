import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { CameraMeta } from '../types/camerameta';

@Injectable({
  providedIn: 'root',
})
export class AcCameraService {
  currentTailNo = '';
  cameras: CameraMeta[] = [];
  selectedCameras: CameraMeta[] = [];
  mediaListBehavior = new BehaviorSubject<CameraMeta[]>(this.cameras);

  constructor(
    private http: HttpClient,
  ) {}

  getCameraList(selTailNo: string): Observable<any> {
    this.currentTailNo = selTailNo;
    const url = `${environment.api}/ac-command/cmd/${selTailNo}/list-cameras/42`;
    return this.http.post<CameraMeta[]>(url, {});
  }

  loadCameraList(selTailNo: string): void {
    this.cameras = [];
    this.getCameraList(selTailNo).subscribe();
  }

  public setCameraList(camList: CameraMeta[]) {
    this.cameras = camList;
  }

  private parseMediaType(value: string): string {
    if (value.toUpperCase() === 'VIDEO') {
      return 'video_library';
    } else {
      return '';
    }
  }
}
