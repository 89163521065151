<div>
  <div @divState class="return-action-container" mat-dialog-actions>
    <div>
      <h2 mat-dialog-title class="h1-settings">{{title}}</h2>
    </div>
    <mat-icon class="icon-settings-close" mat-dialog-close>close</mat-icon>
  </div>

  <form class="form" [formGroup]="CameraFilterForm" (ngSubmit)="submit()">
  <div class="filter-container">
 
    <div>
      <mat-form-field class="mat-form-field-container">
        <mat-label>Camera selection</mat-label>
        <mat-select formControlName="fcCameraList" multiple>
          <mat-option *ngFor="let camera of this.acCameraService.cameras" [value]="camera">
            {{camera.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="mat-form-field-container">
        <mat-label>Start Time</mat-label>
        <input matInput [ngxMatDatetimePicker]="startpicker" placeholder="Start Time"
          formControlName="fcStartTime" [value]="startpicker">
        <ngx-mat-datepicker-toggle matSuffix [for]="startpicker"></ngx-mat-datepicker-toggle>
        <ngx-mat-datetime-picker #startpicker [showSpinners]="showSpinners" [showSeconds]="showSeconds">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="mat-form-field-container">
        <mat-label>End Time</mat-label>
        <input matInput [ngxMatDatetimePicker]="stoppicker" placeholder="End Time"
          formControlName="fcEndTime" [value]="stoppicker">
        <ngx-mat-datepicker-toggle matSuffix [for]="stoppicker"></ngx-mat-datepicker-toggle>
        <ngx-mat-datetime-picker #stoppicker [showSpinners]="showSpinners" [showSeconds]="showSeconds">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
 
    <div>
      <mat-form-field class="mat-form-field-container">
        <mat-label>Filter type</mat-label>
        <mat-select formControlName="fcFilterType">
          <mat-option *ngFor="let filterType of filterTypes" [value]="filterType">
            {{filterType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="button-container">
      <button class="submit-button" mat-raised-button color="primary" type="submit" [innerHTML]="'Submit'"></button>
    </div>
  </div>
  </form>
</div>