import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public showAbout = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async logout() {
    await this.authService.logOut();
  }

  async reLogin() {
    await this.authService.reLogIn();
  }

  async navigateToAcInfoList() {
    await this.router.navigate(['/acinfo']);
  }
}
