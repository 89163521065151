import { Component, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { AcVideoService } from '../shared/service/ac-video.service';
import { AcCameraService } from '../shared/service/ac-camera-list.service';

export interface IParams {
  tailNo: string;
  dialog: MatDialog;
}
@Component({
  selector: 'app-ac-video-filter',
  templateUrl: './ac-video-filter.component.html',
  styleUrls: ['./ac-video-filter.component.scss'],
  animations: [
    trigger('divState', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(0) scale(0.5)',
        }),
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'translateX(0) scale(1)',
        }),
      ),
      transition(':enter', animate('300ms ease-in')),
      transition(':leave', animate('100ms ease-out')),
    ]),
  ],
})

export class AcVideoFilterComponent implements OnInit {
  CameraFilterForm!: FormGroup;
  public title = 'A/C media filter';
  public now = new Date();
  filterTypes: string[] = ['contains', 'intersects'];
  selectedfilterType = 'this.filterTypes[0]';
  cameraList: string[] = ['cam1', 'cam2'];
  cameraSelection: string[] = [];

  public disabled = false;
  public showSpinners = true;
  public showSeconds = true;
  public color: ThemePalette = 'primary';

  constructor(
    private formBuilder: FormBuilder,
    private acVideoService: AcVideoService,
    public acCameraService: AcCameraService,
    @Inject(MAT_DIALOG_DATA) public data: IParams,
  ) {}

  ngOnInit(): void {
    this.CameraFilterForm = this.formBuilder.group({
      fcCameraList: new FormControl(null),
      fcStartTime: new FormControl(new Date()),
      fcEndTime: new FormControl(new Date()),
      fcFilterType: new FormControl(null),
    });
    this.cameraList = this.acCameraService.cameras.map((cam) => {
      return cam.name;
    });
  }

  async submit() {
    const cameraList = this.CameraFilterForm.get('fcCameraList')?.value;
    const startTime: Date = this.CameraFilterForm.get('fcStartTime')?.value;
    const endTime = this.CameraFilterForm.get('fcEndTime')?.value;
    const filterT = this.CameraFilterForm.get('fcFilterType')?.value;

    const params = {
      filterType: filterT,
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    };

    await this.acVideoService.loadVideoList(this.data.tailNo, params, cameraList);
    this.data.dialog.closeAll();
  }
}
